import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Container,Row,Col} from 'react-bootstrap';
import SocialIcon from './sections/footersocial/SocialIcon';
import ScrollButton from './ScrollButton';
export default class FooterComp extends Component {
  render() {
    return (
      <footer>
          <div className="footer-top">
              <Container>
                  <Row>
                  <Col md={9}>
                          <h2>Boost traffic, generate leads and increase sales with our SEO services</h2>
                    </Col>
                    <Col md={3}>
                          <Link to="./seo-karachi" className='mi-btn'>More Info</Link>
                    </Col>
                      </Row>
                  </Container>
              </div>
              <div className="midfooter">
                  <Container>
                      <Row>
                          <Col md={3}>
                          <Link to="/"><img src='/images/logo.gif' alt="Logo" width="100%"/></Link>
                          <p>Founded in 2010—we are a professional web design company providing Web Development, Graphic Design, SEO, and Digital Marketing.</p>
                            
                         </Col>
                          <Col md={3}>
                              <h4>Services</h4>
                              <ul>
                                  <li><Link to="./web-design-karachi">Website Design</Link></li>
                                  <li><Link to="./ecommerce-website-design">Bespoke Web Development</Link></li>
                                  <li><Link to="./ecommerce-website-design">ECommerce Development</Link></li>
                                  <li><Link to="./seo-karachi">Search Engine Optimization</Link></li>
                                  <li><Link to="./digital-marketing">Digital Marketing</Link></li>
                                  <li><Link to="./web-design-packages">Branding & Graphic Design</Link></li>
                              </ul>
                            </Col>
                          <Col md={3}>
                          <h4>Our Offices</h4>     
                          <p>Office # 711,<br/>
                          7th floor,<br/>
                          Mashriq Center,<br/>
                          Block-14,<br/>
                          Gulshan-e-iqbal,<br/>
                          Karachi</p>
                        </Col>
                          <Col md={3}>
                          <h4>Social Media</h4> 
                          <ul className='social-link'>
                              <li><SocialIcon link="https://www.facebook.com/digitalagencykarachi/" icon="fa fa-facebook-official"/></li>
                              <li><SocialIcon link="https://www.pinterest.com/digiphixwebdesignkarachi " icon="fa fa-pinterest-p"/></li>
                              <li><SocialIcon link="https://www.instagram.com/digiphixdigital/" icon="fa fa-instagram"/></li>
                              <li><SocialIcon link="https://www.linkedin.com/company/digiphix/ " icon="fa fa-linkedin"/></li>
                          </ul>
                              
                              </Col>
                      </Row>
                  </Container>
                  </div>
                  <div className="bottom-footer">
                      <Container>
                          <Row>
                              <Col>
                                <p>© 2022 Digiphix Website Design Company.</p>  
                                </Col>
                          </Row>
                      </Container>
                      </div>
                      <div className='call-btn'>
                        <a href='tel:03002628850'><i className="fa fa-phone" aria-hidden="true"></i></a>
                      </div>
                      <ScrollButton/>
      </footer>
    )
  }
}
