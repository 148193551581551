import React, { useState } from 'react';
import {Container, Row, Col,Form, Button} from 'react-bootstrap';
import axios from 'axios';

export default function FormBanner() {
  const [sent,setSent] = useState(false)
  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [aboutus,setAboutUs] = useState("")
  const [projectDetails,setProjectDetails] = useState("")
  const handleSend = async (event) => {
    event.preventDefault();
    
    if (event.target[0].value) {
      setSent(true)
    }
    else{
      setSent(false)
    }
    try{
      await axios.post("https://mailapi.digiphix.pk/ecommerce_mail",{name,email,phone,aboutus,projectDetails})
    } catch(error)
    {
      console.log(error)
    }
  }
  return (
    <div className="main-banner-section" style={{ backgroundImage: "url(/images/pages/ecommerce-website-maker-karachi.jpg)"}}> 
        <Container fluid>
            <Row>
                <Col md={8}>
                    <div className="shape-text">
                        <h4>E-COMMERCE CUSTOM WEBSITE DESIGN</h4>
                        <h2>Growing Your Business with Ecommerce Online</h2>
                        <p>Our highly experienced ecommerce designers and developers which can creates ecommerce enterprise web sites bloom and successfully.</p>
                    </div>
                </Col>
                <Col md={4}>
                <div className="service-form">
                <Row>
                <Col md={12}>
                    <h5>LET'S TALK!</h5>
                    <a href="tel:+923002628850"><img src="/images/service-page/pk-flag.jpg" alt="Flag"/>+923002628850</a>
                    <h4>GET YOUR <strong>FREE</strong> WEBSITE LAYOUTS</h4>
                </Col>
                </Row>  
                <Form onSubmit={handleSend}>
                <Row>
             <Col md={12}>
             <Form.Control type="text" value={name} onChange={(e)=> setName(e.target.value)} placeholder="Full Name *" required />
             </Col>
             <Col md={12}>
             <Form.Control type="text" value={phone} onChange={(e)=> setPhone(e.target.value)} placeholder="Phone *" required />
             </Col>
             <Col md={12}>
             <Form.Control type="email" value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Email *" required />
             </Col>
             <Col md={12}>
             <Form.Control type="text" value={aboutus} onChange={(e)=> setAboutUs(e.target.value)} placeholder="How did you know about us?" />
             </Col>
             <Col md={12}>
             <Form.Control as="textarea" value={projectDetails} onChange={(e)=> setProjectDetails(e.target.value)} rows={3} placeholder="Project Details / Description *" required/>
             </Col>
             <Col md={12}>
             <Button type="submit">Request FREE Layout</Button>
             </Col>
           </Row>
         </Form>
         {!sent ? ("") : (<p className='message-output'>Message  Send Successfully</p>)}
         </div>
                </Col>
            </Row>
        </Container>
       
    </div>
  )
}
