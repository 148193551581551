import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BlogList from './blog/blogprops/BlogList';
export default function Blog() { 
  return (
    <HelmetProvider>
    <div className='main-blog-page'>
      <Helmet>
      <title>Information Technology Blog | Web Design, Ecommerce, SEO, SMM</title>
      <meta name="description" content="Find helpful tips, trends, and strategy to related web design, ecommerce, seo, smm. Our blog helps you grow an online business successfully."/>
      </Helmet>
         <div className="page-banner" style={{ backgroundImage: "url(/images/pages/blog-karachi-web-designer.jpg)" }}>
          <Container>
            <Row>
              <Col><h1>Blog</h1>
              </Col>
              
            </Row>
          </Container>
        </div>
        <div className='all-blog'> 
        <Container>
          <Row>
            <BlogList 
             blogLink={'/10-company-website-design-inspiration-templates'}
             imgSrc={'/images/blog/seo-software-featured-image.webp'}
             blogTitle={'10 Company Website Design Inspiration & Templates'}
             authorImgSrc={'/images/blog/kayla.png'}
             blogType={'Web Design'}
             blogMeta={'By Abby Fields on June 5, 2022'}
             blogText={"Your website is at the center of all your digital marketing efforts. Whether you're running paid ads, sending out emails, or posting on social media, all your marketing materials ultimately lead users right back to your website. That means your..."}
            />
             <BlogList 
             blogLink={'/node-js-development-services'}
             imgSrc={'/images/blog/seo-software-featured-image.webp'}
             blogTitle={'Node.js Development Services'}
             authorImgSrc={'/images/blog/kayla.png'}
             blogType={'Web Design'}
             blogMeta={'By Abby Fields on Sept 23, 2022'}
             blogText={"Need reliable and performance-based scalability for your existing and next projects? Digiphix provide Node.js services that will make you carefree from all your coding issues. The tech giants out there have built their apps with Node.js ..."}
            />
            
          </Row>
      </Container>
        </div>
      
  </div>
  </HelmetProvider>
  )
}

