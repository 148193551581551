import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Project from './sections/home/Project';
export default function Portfolio() { 
  return (
    <HelmetProvider>
    <div className='webdsgnpck'>
    <Helmet>
    <title>Web Design Portfolio in Karachi | Ecommerce Solutions Karachi</title>
    <meta name="description" content="Check out our portfolio of web design, ecommerce websites related Laravel, wordpress, magento, shopify and web applications."/>
    </Helmet>
    <div className="page-banner" style={{ backgroundImage: "url(/images/pages/who-we-are-img.jpg)" }}>
      <Container>
        <Row>
          <Col><h1>Portfolio</h1></Col>
        </Row>
      </Container>
    </div>
    <div className="portfolio-section">
            <Container>
                <Row>
                <Col>
                        <h2>Check out our portfolio of recent work</h2>
                    </Col>
                </Row>
                <Row>
                    <Project imgsrc="/images/projects/commodities.jpg" btnlink="https://www.sherdileximp.com/"/>
                    <Project imgsrc="/images/projects/desert-safari.jpg" btnlink="https://desertsafaridubai.info/"/>
                    <Project imgsrc="/images/projects/east-evenue.jpg" btnlink="https://eastavenuenj.com"/>
                    <Project imgsrc="/images/projects/mac.jpg" btnlink="https://www.macmedcable.com/"/>
                </Row>
                <Row>
                    <Project imgsrc="/images/projects/mepl.jpg" btnlink="http://mepl-intl.com/"/>
                    <Project imgsrc="/images/projects/surgicare.jpg" btnlink="https://www.umwmedia.com/work/surgi-care/"/>
                    <Project imgsrc="/images/projects/texworld.jpg" btnlink="https://texworld.com.pk/"/>
                    <Project imgsrc="/images/projects/toplar.jpg" btnlink="https://umwteam.com/21/toplar/site/"/>
                </Row>
                </Container>
            </div>
   
  </div>
  </HelmetProvider>
  )
}

