import React, { Component } from 'react';
import {Link} from 'react-router-dom';
export default class Service extends Component {
  render() {
    return (
        <div className="col-md-3"> 
        <div className="service-box">
        <div className='service-img'>
            <img src={this.props.imgsrc} alt={this.props.title} />
        </div>
        <div className="service-text">
        <Link to={this.props.link}>{this.props.title}</Link>  
        <p>{this.props.description}</p>  
        </div>
        </div>
    </div>
    )
  }
}
