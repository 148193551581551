import './App.css';
import './assets/style.css';
import './assets/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderComp from './components/HeaderComp';
import FooterComp from './components/FooterComp';
import {BrowserRouter} from "react-router-dom";
// import About from './components/About';
// import { BrowserRouter as  Routes, Route } from "react-router-dom";
function App() {
  return (
    <>
     <BrowserRouter>
    <HeaderComp/>
    <FooterComp/>
    </BrowserRouter>
    </>
  );
}

export default App;
