import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import ArticleList from './blogprops/ArticleList';
import {Link} from 'react-router-dom';
import {Link as ScrollLink} from 'react-scroll';
import $ from 'jquery';
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default function CompanyWebsiteDesign() { 
    $(".widget_collapse-head").click(function(){
      this.toggleClass('show');
    });
  return (
    <HelmetProvider>
      <Helmet>
      <title>10 Company Website Design Inspiration & Templates</title>
      <meta name="description" content="Are you looking for corporate website examples to help you with your design? We've enlisted our top 10 web design niches that can your professional requirements."/>
      </Helmet>
    <div className='blog-page'>
      <Container>
        <Row>
          <Col md={12}>
            <div className='blog-breadcrumb'>
              <ul>
                <li><Link to={'/'}>Home</Link></li>
                <li><Link to={'/blog'}>Blog</Link></li>
                <li>Web Design</li>
              </ul>
            </div>
            <div className='blog-title'>
            <h1>10 Company Website Design Inspiration & Templates</h1>
            </div>
            
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={8}>
          <div className='blog-pragraph'>
          <p>A website is the first touchpoint of every business, whether a small, medium or large enterprise. Since every business now has an online presence, a sophisticated website design could help you bring stability because every visitor will judge your credibility based on your web design and templates. So it's crucial to choose a design wisely with a great user interface that can help you bring more customers. </p>
            <p>Are you looking for corporate website examples to help you with your design? </p>
            <p>Then you're at the right place. We've enlisted our top 10 web design niches that can your professional requirements. So without any further delay, let's get straight into it.</p>
            </div>
            <div className='blog-list'>
            <ArticleList 
                id={1}
                anchor={'business'}
                title={'Business'}
                titlelink={'/'}
                imgsrc={'/images/blog/natives.gif'}
                article={'Here we have something absolutely stunning for corporate sector. To achieve your business objectives, a sophisticated web design that matches your sales is mandatory. '}
                listHeading={'Business Template covers in the following business areas.'}
                list={[
                  "Consultancy & Consultants",
                  "Digital Business",
                  "Service Industry"
                ]} 
              />
              <ArticleList 
                  id={2}
                  anchor={'construction'}
                  title={'Construction'}
                  titlelink={'/'}
                  imgsrc={'/images/blog/natives.gif'}
                  article={'The next on our list is construction designs. Let your audience know your building skills with our robust web designs and templates. Here are the following construction categories we have design for.'}
                  listHeading={'Construction Template covers in the following construction industry.'}
                  list={[
                    "Civil & Mechanicals",
                    "Builders & Constructions",
                    "Building Materials",
                    "Electrical & Engineers"
                  ]} 
              />
               <ArticleList 
                  id={3}
                  anchor={'education'}
                  title={'Education'}
                  titlelink={'/'}
                  imgsrc={'/images/blog/natives.gif'}
                  article={'We have some great educational templates for your institutional portal that will help student in a lot better way. From school, colleges and universities, these templates are equally beneficial for all of them'}
                  listHeading={'Education Template covers in the following education institution.'}
                  list={[
                    "Schools",
                    "Colleges",
                    "Universities",
                    "Institutes & Teachers"
                  ]} 
              />
            <ArticleList 
                  id={4}
                  anchor={'lawlegal'}
                  title={'Law & Legal'}
                  titlelink={'/'}
                  imgsrc={'/images/blog/natives.gif'}
                  article={"Let your legal firm has some class. Our law templates will leave a very bold impression on your clients that they won't be able to resist in booking appointment with you."}
                  listHeading={'Law Template covers in the following legal departments.'}
                  list={[
                    "Civil Lawyers",
                    "Business Lawyers",
                    "Company Incorporate & Business Registration"
                  ]} 
              />
              <ArticleList 
                  id={5}
                  anchor={'importexport'}
                  title={'Import & Export'}
                  titlelink={'/'}
                  imgsrc={'/images/blog/natives.gif'}
                  article={"If you are engaged in trading business, this one’s for you. We have a wide range of import and export templates in following categories. Check them out."}
                  listHeading={'Import & Export Template covers in the following departments.'}
                  list={[
                    "Trading & Traders",
                    "Rice, Pluses, Fish,",
                    "Commodities"
                  ]} 
              />
              <ArticleList 
                  id={6}
                  anchor={'transportation'}
                  title={'Transportation & Logistics'}
                  titlelink={'/'}
                  imgsrc={'/images/blog/natives.gif'}
                  article={"Transportation is a demanding business, so you have to stand out from others. And this is possible by choosing the most user friendly web design for your logistic website. We deal in following freight and transportation design."}
                  listHeading={'Logistics & transportation Template covers in the following departments.'}
                  list={[
                    "Air Freights",
                    "Sea Frights",
                    "Road Freights",
                    "Warehousing ",
                    "Supply Chain Management"
                  ]} 
              />
              <ArticleList 
                  id={7}
                  anchor={'medical'}
                  title={'Medical & Health'}
                  titlelink={'/'}
                  imgsrc={'/images/blog/natives.gif'}
                  article={"The next website we'll look at is in the medical field. Health, insurance, clinics, and hospitals that provide your visitors with a memorable experience"}
                  listHeading={'Health & Medical Template covers in the following departments.'}
                  list={[
                    "Hospital & Clinics",
                    "Medical Lab & Center",
                    "Doctors & Nurses"
                  ]} 
              />
               <ArticleList 
                  id={8}
                  anchor={'travel'}
                  title={'Travel & Tourism'}
                  titlelink={'/'}
                  imgsrc={'/images/blog/natives.gif'}
                  article={"Here is the most adventurous web designs you are about to see. If you deal in traveling agency then check out our following tourism templates to entice your clients. "}
                  listHeading={'Travel & Tourism Template covers in the following departments.'}
                  list={[
                    "Tickets & Flights",
                    "Hotel & Motel",
                    "Car Rental"
                  ]} 
              />
              <ArticleList 
                  id={9}
                  anchor={'ecommerce'}
                  title={'Ecommerce Shops'}
                  titlelink={'/'}
                  imgsrc={'/images/blog/natives.gif'}
                  article={"Check out these ecommerce shop templates to give your customers a great shopping experience. The website designs deal in footwear, clothing and electronics. Check them out"}
                  listHeading={'Ecommerce Online Shop Template covers in the following industry.'}
                  list={[
                    "Apparel & Clothing",
                    "Shoes & Footwear",
                    "Electronics & Appliances"
                  ]} 
              />
              <ArticleList 
                  id={10}
                  title={'Govt Organization'}
                  anchor={'organization'}
                  titlelink={'/'}
                  imgsrc={'/images/blog/natives.gif'}
                  article={"We have something for our government bodies as well. We deal in designs suitable for officials like local bodies, Government ministries. Check out the following categories."}
                  listHeading={'Govt Template covers in the following departments.'}
                  list={[
                    "Ministry of Government",
                    "Different Departments",
                    "States of Local bodies"
                  ]} 
              />
            </div>
            
          </Col>
          <Col md={4}>
            <div className='widget_collapse'>
              <div className='widget_collapse-head'>
                <h4 className="widget_collapse-title"><i className="fa fa-bars" aria-hidden="true"></i> Table of Contents</h4>
              </div>
              <div className='widget_collapse-body'>
                  <ul className='list-link'>
                    <li><ScrollLink to={'business'} spy={true} smooth={true}>Business</ScrollLink></li>
                    <li><ScrollLink to={'construction'} spy={true} smooth={true}>Construction</ScrollLink></li>
                    <li><ScrollLink to={'education'} spy={true} smooth={true}>Education</ScrollLink></li>
                    <li><ScrollLink to={'lawlegal'} spy={true} smooth={true}>Law & Legal</ScrollLink></li>
                    <li><ScrollLink to={'importexport'} spy={true} smooth={true}>Import & Export</ScrollLink></li>
                    <li><ScrollLink to={'transportation'} spy={true} smooth={true}>Transportation & Logistics</ScrollLink></li>
                    <li><ScrollLink to={'medical'} spy={true} smooth={true}>Medical & Health</ScrollLink></li>
                    <li><ScrollLink to={'travel'} spy={true} smooth={true}>Travel & Tourism</ScrollLink></li>
                    <li><ScrollLink to={'ecommerce'} spy={true} smooth={true}>Ecommerce Shops</ScrollLink></li>
                    <li><ScrollLink to={'organization'} spy={true} smooth={true}>organization</ScrollLink></li>
                  </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
                  <h2>Create a world-class company website with Digiphix!</h2>
                  <p>Ready to get started with your own company website? Check out our fresh and exciting web design plans.</p>
                  <p>Contact us online or call us at <button onClick={() => window.location = 'tel:03340245951'}>0334-0245951</button> for a custom website designing.</p>
          </Col>
        </Row>
      </Container>
  </div>
  </HelmetProvider>
  )
}

