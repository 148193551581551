import React, { Component } from 'react';
export default class Project extends Component {
  render() {
    return (
        <div className="col-md-3">
        <div className="portfolio-box">
        <img src={this.props.imgsrc} alt="Portfolio" />
        <a href={this.props.btnlink} target="_blank" rel="noreferrer"  className='sp-btn'>See project</a>
        </div>
    </div>
    )
  }
}
