import React, { Component } from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import WdSteps from './sections/WdSteps';
import FormBanner from './sections/FormBanner';
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default class EcommerceDesign extends Component {
  render() {
    return (
      <HelmetProvider>
      <div className='ecommerce-page'>
        <Helmet>
        <title>Ecommerce Website Design Karachi | Shopify, WordPress, Magento</title>
        <meta name="description" content="Design online stores, retailers, service industries, start-ups, and many more industries. Explore how to Grow your ecommerce business with us."/>
        </Helmet>
         <FormBanner/>
         <div className="ecommerce-webdesign">
               <Container>
                 <Row>
                   <Col>
                   <h2>Design online stores, retailers, service industries, start-ups, and many more industries</h2>
                   <p>Digiphix provides the best e-commerce website design services and a variety of open-source platforms like Shopify, WordPress, Magento & Laravel. We focus on Ecommerce website development to help your business Worldwide.</p>
                   </Col>
                 </Row>
               </Container>
               <Container className='ecommerce-logo text-center'>
                 <Row>
                   <Col md={12}>
                   <div className='logo-icon'>
                      <img src="/images/pages/laravel.png" alt="Logo" />
                      <img src="/images/pages/shopify.webp" alt="Logo" />
                      <img src="/images/pages/magento.webp" alt="Logo" />
                      <img src="/images/pages/woocommerce.webp" alt="Logo" />
                   </div>
                   </Col>
                 </Row>
               </Container>
               <Container className='zigzag-section magento'>
                 <Row>
                   <Col md={5}>
                   <img src="/images/pages/magento.webp" alt="Logo" />
                   
                   <h2>Magento Development</h2>
                   <p>Magento is a powerful e-commerce platform to develop high-performance and B2B commerce websites with a flexible shopping cart system. It is a multifunctional platform that includes the features of creating, managing, and promoting a business online.</p>
                    <Link to="/" className='web-link'>Explore how to Grow your business</Link>
                   </Col>
                   <Col md={7}>
                    <img className='fullwidth' src="/images/pages/becker-shoe.webp" alt="Magento" />
                   </Col>
                 </Row>
               </Container>
               <Container className='zigzag-section shopify'>
                 <Row>
                   <Col md={7}>
                    <img className='fullwidth' src="/images/pages/rumina-natural.webp" alt="Magento" />
                   </Col>
                   <Col md={5}>
                   <img src="/images/pages/shopify.webp" alt="Logo"/>
                   <h2>Shopify Development</h2>
                   <p>Shopify is the latest e-commerce platform and fast loading website performance that meet your SEO goals. Shopify is not free because they charge a monthly subscription to enable your web store.</p>
                    <Link to="/" className='web-link'>Explore how to Grow your business</Link>
                   </Col>
                 </Row>
               </Container>
               <Container className='zigzag-section woocommerce'>
                 <Row>
                   <Col md={5}>
                   <img src="/images/pages/woocommerce.webp" alt="Logo" />
                   <h2>Woocommerce Development</h2>
                   <p>Woocommerce is the most common e-commerce plug-in for Word press. Every starting business chooses Woo Commerce Ecommerce Website Design because 99% of plugins are free and low-cost development in the market.</p>
                    <Link to="/" className='web-link'>Explore how to Grow your business</Link>
                   </Col>
                   <Col md={7}>
                    <img className='fullwidth' src="/images/pages/becker-shoe.webp" alt="Magento" />
                   </Col>
                 </Row>
               </Container>
               <Container className='zigzag-section laravel'>
                 <Row>
                   <Col md={7}>
                    <img className='fullwidth' src="/images/pages/rumina-natural.webp" alt="Magento" />
                   </Col>
                   <Col md={5}>
                   <img src="/images/pages/laravel.png" alt="Logo"/>
                   <h2>Laravel Development</h2>
                   <p>Laravel is a PHP web application framework, a bespoke platform that provides 100% customization with a flexible shopping cart system as per client expectations. It is a demanding platform in the market to develop any type of e-commerce and web application.</p>
                    <Link to="/" className='web-link'>Explore how to Grow your business</Link>
                   </Col>
                 </Row>
               </Container>
             </div>
             <div className="what-we-do">
               <Container className='own-cstm-wbst-dsn text-center'>
                 <Row>
                   <Col md={12}>
                   <h2>Take a Step to Our Ecommerce Website Design Process</h2>
                    <p>Build a unique identity with seamless design and impressive content for your business.</p>
                   </Col>
                 </Row>
                 <Row className='steps-box'>
                    <WdSteps imgsrc="/images/service-page/pick-a-layout.svg" title="Design a Layout" hovertext="Creative Wireframes related to your business"/>
                    <WdSteps imgsrc="/images/service-page/convert-to-wordpress.svg" title="Revision & Changes" hovertext="Unlimited Design Revisions as per client feedback"/>
                    <WdSteps imgsrc="/images/service-page/adjust-extra-modifications.svg" title="Convert Design to Development" hovertext="Deploy wireframe into the best platform like WordPress, Laravel"/>
                    <WdSteps imgsrc="/images/service-page/launched-website.svg" title="Testing & Live" hovertext="Check the website on all devices & make the website live within 24 hours"/>
                 </Row>
               </Container>
             </div>
    </div>
    </HelmetProvider>
    )
  }
}
