import React, { Component } from 'react'
import {Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default class BlogList extends Component {
  render() {
    return (
        <Col md={6}>
            <div className='blog-post'>
                <div className='blog-img'>
                    <Link to={this.props.blogLink}>
                    <img src={this.props.imgSrc} alt="Blog Post" />
                    <span>{this.props.blogType}</span>
                    </Link>
                </div>
                <div className='blog-body'>
                    <h4><Link to={this.props.blogLink}>{this.props.blogTitle}</Link></h4>
                    <div className='post-meta'>
                        <img src={this.props.authorImgSrc} alt="Author"/>
                        <span>{this.props.blogMeta} </span>
                    </div>
                    <p>
                    {this.props.blogText}
                    </p>
                    <Link className='btn-post' to={this.props.blogLink}>View Full Story <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>
                </div>
            </div>
        </Col>
    )
  }
}
