import React, { Component } from 'react';
import {Col} from 'react-bootstrap';

export default class WdSteps extends Component {
  render() {
    return (
        <Col md={3} sm={6}>
        <div className='steps'>
          <img src={this.props.imgsrc} alt="Icon" width="45px" />
          <h5>{this.props.title}</h5>
          <p className='visibleonhover'>{this.props.hovertext}</p>
        </div>
     </Col>
    )
  }
}
