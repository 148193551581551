import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import {Link} from "react-router-dom";

export default class ArticleList extends Component {
  render() {
    
    const lists = this.props.list;
    const listItems = lists.map((list) =>
      <li key={list}>{list}</li>
    );
    return (
        <Row>
            <Col md={12}>
              <div className='post-article' id={this.props.anchor}>
                <h1>{this.props.id}. <Link to={this.props.titlelink}>{this.props.title}</Link></h1>
                <div className='article-image'>
                    <img src={this.props.imgsrc} alt={'Website'} />
                </div>
                <p>{this.props.article}</p>
                <h3>{this.props.listHeading}</h3>
                <ul>{listItems}</ul>
                </div>
            </Col>
        </Row>    
    )
  }
}
