import React, { Component } from 'react'
import Project from './sections/home/Project';
import Service from './sections/home/Service';
import {Container,Row,Col,Carousel} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default class Home extends Component {
  render() {
    return (
        <HelmetProvider>
        <div className='home-page'>
            <Helmet>
            <title>Business Web Design - React Js, Laravel, Shopify, WordPress in Karachi, Pakistan</title>
            <meta name="description" content="Digiphix started in 2010 as a web design company in Karachi, Pakistan. We have helped businesses to grow in multiple industries across Karachi including Korangi Industrial Area, Shahrah-e-Faisal, S.I.T.E Area, West Wharf, and Gulshan-e-Iqbal."/>
            </Helmet>
      <div className='main-banner'>
          <div className="video-bg">
          <video autoPlay={"autoplay"} loop muted>
        <source src="/video/laptop-4.mp4" type="video/mp4" />
        </video>
              </div>
     
    <Container>
        <Row>
            <Col>
                <h1>Business Web Design / React Js, Laravel, Shopify, WordPress in  <strong> Karachi, Pakistan</strong> </h1>
                <span className='solog'>Helping businesses to grow since 2010.</span>
            </Col>
        </Row>
        <Row>
            <Service imgsrc="/images/home-icons-1.png" title="Website Design" link="./web-design" 
            description="Creative, mobile-responsive websites starting from 45,000/="
            />
            <Service imgsrc="/images/white-icons-4.png" title="Web Development" link="./ecommerce-website-design" 
            description="MERN stack development solutions to give you a competitive edge."
            />
            <Service imgsrc="/images/home-icons-2.png" title="SEO Services" link="./seo" 
            description="Rank your website on the First page of Google Search with Organic SEO Services."
            />
            <Service imgsrc="/images/home-icons-3.png" title="Digital Marketing" link="./digital-marketing" 
            description="Boost your products and services on social media platforms including Facebook, Instagram."
            />
            </Row>
    </Container>
     </div>
     <div className="find-out-more">
        <Container>
            <Row>
                <Col md={9}>
                    <h3>Websites for Ecommerce Business and Online Shop Design from 75,000/=</h3>
                    <p>We have flexible website design pricing options available, including everything you need to get started</p>
                    </Col>
                    <Col md={3}>
                        <Link to="./web-design-packages" className='fom-btn'>Find Out More</Link>    
                    </Col>
                </Row>
            </Container>     
    </div>
        <div className="portfolio-section">
            <Container>
                <Row>
                <Col>
                        <h2>Check out our portfolio of recent work</h2>
                    </Col>
                </Row>
                <Row>
                    <Project imgsrc="/images/projects/commodities.jpg" btnlink="https://www.sherdileximp.com/"/>
                    <Project imgsrc="/images/projects/desert-safari.jpg" btnlink="https://desertsafaridubai.info/"/>
                    <Project imgsrc="/images/projects/east-evenue.jpg" btnlink="https://eastavenuenj.com"/>
                    <Project imgsrc="/images/projects/mac.jpg" btnlink="https://www.macmedcable.com/"/>
                </Row>
                <Row>
                    <Project imgsrc="/images/projects/mepl.jpg" btnlink="http://mepl-intl.com/"/>
                    <Project imgsrc="/images/projects/surgicare.jpg" btnlink="https://www.umwmedia.com/work/surgi-care/"/>
                    <Project imgsrc="/images/projects/texworld.jpg" btnlink="https://texworld.com.pk/"/>
                    <Project imgsrc="/images/projects/toplar.jpg" btnlink="https://umwteam.com/21/toplar/site/"/>
                </Row>
                </Container>
            </div>
            <div className="web-design">
                <Container>
                    <Row className='web-head'>
                        <Col>
                        <h2>Pakistan Top MERN stack development company & Reliable Web Design Services in Karachi </h2>
                        <p>We are a digital marketing agency that can help you create a lasting brand that resonates with your customers in Karachi, Pakistan. We deliver outstanding results that empower our clients.</p>
                        <p>Digiphix started in 2010 as a web design company in Karachi, Pakistan, and has grown to become a full-service MERN stack website design and digital marketing agency. We have helped businesses to grow in multiple industries across Karachi including Korangi Industrial Area, Shahrah-e-Faisal, S.I.T.E Area, West Wharf, and Gulshan-e-Iqbal. </p>
                        <p>We have vast experience and high-quality skills in website design, web development, WordPress development, domain registration, web hosting, logo design, brand identity, graphic design, mobile apps, and digital marketing services including SEO and PPC.</p>
                        </Col>
                    </Row>
                    <Row className='web-section'>
                        <Col md={7}><img src="/images/Digipix-Ecommerce.jpg" alt="Web Design"/></Col>
                        <Col md={5}><h3>Website Design</h3>
                        <p>We offer a variety of website design services that promote your products, services and empower your brand. Our website designer creates world-class design templates that speak to customers and provide a user experience.</p>
                        <Link to="./web-design-karachi" className='web-link'>Explore how to Grow your business </Link>
                        </Col>
                        </Row>
                        <Row className='web-section'>
                        <Col md={5}><h3>Ecommerce Store</h3>
                        <p>We've worked in the most demanding e-commerce platforms including Shopify, Magento & Woocommerce. We are also providing custom eCommerce design services that can meet your business goals and customer expectations.</p>
                        <Link to="./ecommerce-website-design" className='web-link'>Magento </Link> &nbsp;&nbsp;&nbsp;&nbsp; <Link to="./ecommerce-website-design" className='web-link'>Shopify </Link>
                        </Col>
                        <Col md={7}><img src="/images/Ecommerce-Concept.jpg" alt="Web Design"/></Col>
                        </Row>
                        <Row className='web-section'>
                        <Col md={7}><img src="/images/Digital-Marketing.jpg" alt="Web Design"/></Col>
                        <Col md={5}><h3>Digital Marketing</h3>
                        <p>We provide result-oriented digital marketing services to the best ROI. It's doesn't matter whether your business is small or large, we provide customise digital marketing packages as per your valuable budget.</p>
                        <Link to="./digital-marketing" className='web-link'>Explore how to Grow your business </Link>
                        </Col>
                        </Row>
                </Container>
                </div>
                <div className="digi-testimonials">
                    <Container>
                        <Row className='testi-head'>
                            <Col><h2>Honest feedback from real customers</h2></Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col>
                            <Carousel variant="dark">
                            <Carousel.Item>
                                <div className="profile">
                                    <div className="identity">
                                        <h3>Kafi Group</h3>
                                        </div>    
                                </div>
                                <div className='feedback-message'>
                                    <p>Digiphix guys are awesome and very responsive. I have been so impressed with the design of my company website and guidance which I received from Digiphix Web Design Company. Highly recommended this company for startup businesses.</p>
                                    </div>
                                    <div className="stars">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="profile">
                                    <div className="identity">
                                        <h3>Orak Services</h3>
                                        </div>    
                                </div>
                                <div className='feedback-message'>
                                    <p>I have found the team at digiphix web development very professional and would recommend this company. I look forward to the finished projects.</p>
                                    </div>
                                    <div className="stars">
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                    </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="profile">
                                    <div className="identity">
                                        <h3>Waseem Memon</h3>
                                        </div>    
                                </div>
                                <div className='feedback-message'>
                                    <p>I found excellent web design company to my nearest location gulshan-e-iqbal, The guys are experienced and lovely to explain my questions. I recommended this company!</p>
                                    </div>
                                    <div className="stars">
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                    </div>
                            </Carousel.Item>
                            </Carousel>
                            </Col>
                        </Row>
                    </Container>
                    </div>
     </div>
     </HelmetProvider>
    )
  }
}
