import React, { useState } from 'react'
import {Container, Row, Col,Form, Button} from 'react-bootstrap';
import WdSteps from './sections/WdSteps';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default function WebDesign() {
  const [sent,setSent] = useState(false)
  const [name,setName] = useState("")
  const [company,setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [phone,setPhone] = useState("")
  const [help,setHelp] = useState("")
  const [projectDetails,setProjectDetails] = useState("")

  const handleSend = async (event) => {
    event.preventDefault();
    if (event.target[0].value) {
      setSent(true)
    }
    else{
      setSent(false)
    }
    try{
      await axios.post("https://mailapi.digiphix.pk/web_design_mail",{name,company,email,phone,help,projectDetails})
    } catch(error)
    {
      console.log(error)
    }
  }
  return (
    <HelmetProvider>
    <div className='service-page'>
      <Helmet>
      <title>Company Website Design Karachi | MERN, Laravel & WordPress</title>
      <meta name="description" content="We offer modern and artistic website design services to build your business website on the internet. Get FREE custom website mock-ups within 24 to 48 hours."/>
      </Helmet>
    <div className="main-banner-section">
        <Container fluid>
            <Row>
                <Col md={4}>
                    <div className="form-head">
                    <h2><img src="/images/service-page/bnr-free.svg" alt="Free" width="75px" height="55px" />Website Layouts</h2>
                    <p>Get FREE custom website mock-ups within 24 to 48 hours. No commitment, no obligation, and no cost on your part. Just fill out the form below and click Submit Now.</p>
                    </div>
                    <div className="service-form">
                    <Form onSubmit={handleSend}>
         <Row>
             <Col md={12}>
             <Form.Control type="text" value={name} onChange={(e)=> setName(e.target.value)} placeholder="Full Name *" required />
             </Col>
             <Col md={12}>
             <Form.Control type="text" value={company} onChange={(e)=> setCompany(e.target.value)} placeholder="Company *" required />
             </Col>
             <Col md={12}>
             <Form.Control type="email" value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Email *" required />
             </Col>
             <Col md={12}>
             <Form.Control type="text" value={phone} onChange={(e)=> setPhone(e.target.value)} placeholder="Phone *" required />
             </Col>
             <Col md={12}>
             <Form.Control type="text"  value={help} onChange={(e)=> setHelp(e.target.value)} placeholder="How can we help you today?" />
             </Col>
             <Col md={12}>
             <Form.Control as="textarea" value={projectDetails} onChange={(e)=> setProjectDetails(e.target.value)} rows={3} placeholder="Project Details / Description *" required/>
             </Col>
             <Col md={12}>
             <Button type="submit">Submit Now</Button>
             </Col>
           </Row>
         </Form>
         {!sent ? ("") : (<p className='message-output'>Message  Send Successfully</p>)}
                    </div>
                   
                </Col>
                <Col md={4}>
                   <h2 className='cstm-web-dsgn'> <img src="/images/service-page/bnr-arrow.svg" alt="Arrow" /> Custom Website Design</h2>
                   <h2 className='r35-days'>Ready in 3-5 days!</h2>
                   <ul>
                       <li>Affordable Pricing</li>
                       <li>Responsive Website</li>
                       <li>Unlimited Revisions</li>
                       <li>Free Logo Design</li>
                       <li>Free Copywriting</li>
                       <li>Royalty-Free Photos</li>
                   </ul>
                   <div className='experience'>
                       <img src="/images/service-page/15-years.svg" width="90px" height="90px" alt="15 Years" />
                       <img src="/images/service-page/20k-plus.svg" width="90px" height="90px" alt="20k plus" />
                   </div>
                </Col>
                <Col md={4}>
                   <img className='right-banner' src="/images/Website-Design.png" alt="Banner" />
                </Col>
            </Row>
        </Container>
       
    </div>
    <div className="what-we-do ">
          <Container>
            <Row>
              <Col><h3>WHAT WE DO</h3>
               <h1>Company Website Design</h1>
               <h4>Design your company website in world-class platforms like MERN, Laravel & WordPress. We are always deploying the latest technologies & marketing web trends.</h4>
               <p>We offer modern and artistic website design services to build your business on the internet. Today there are over 1.18 billion websites available worldwide. That's why every company and business requires website design.</p>
              </Col>
            </Row>
          </Container>
          <Container className='own-cstm-wbst-dsn text-center'>
            <Row>
              <Col md={12}>
              <h2>Take a Step to Our Website Design Process</h2>
               <p>Build a unique identity with seamless design and impressive content for your business.</p>
              </Col>
            </Row>
            <Row className='steps-box'>
               <WdSteps imgsrc="/images/service-page/pick-a-layout.svg" title="Design a Layout" hovertext="Creative Wireframes related to your business"/>
               <WdSteps imgsrc="/images/service-page/convert-to-wordpress.svg" title="Revision & Changes" hovertext="Unlimited Design Revisions as per client feedback"/>
               <WdSteps imgsrc="/images/service-page/adjust-extra-modifications.svg" title="Convert Design to Development" hovertext="Deploy wireframe into the best platform like WordPress, Laravel"/>
               <WdSteps imgsrc="/images/service-page/launched-website.svg" title="Testing & Live" hovertext="Check the website on all devices & make the website live within 24 hours"/>
            </Row>
          </Container>
        </div>
        <div className="what-we-do seo-service">
          <Container>
            <Row>
              <Col md={6}><h3>WHAT WE DO</h3>
               <h1>SEO</h1>
               <h4>Our high-end and affordable SEO Services will expand your website's reach in no time.</h4>
               <p>It's the next step to making your business go and stay on top of your rankings. Optimize your website, build your brand, and grow your business with our help!</p>
              </Col>
              <Col md={6}>
                <img src="/images/service-page/seo-img.svg" alt="SEO" width="100%" />
              </Col>
            </Row>
          </Container>
          <Container className='own-cstm-wbst-dsn text-center'>
            <Row>
              <Col md={12}>
              <h2>Take a Step to Our SEO Process</h2>
               <p>Bring attention to your brand. Optimize now!</p>
              </Col>
              
            </Row>
            <Row className='steps-box'>
               <WdSteps imgsrc="/images/service-page/seo-process-1.svg" title="Website Audit" hovertext="Have your website’s visibility analyzed"/>
               <WdSteps imgsrc="/images/service-page/seo-process-2.svg" title="Search Engine Submission" hovertext="Introduce your URL to a Search Engine"/>
               <WdSteps imgsrc="/images/service-page/seo-process-3.svg" title="Keyword Research and Distribution" hovertext="Enrich Your website For better searchability"/>
               <WdSteps imgsrc="/images/service-page/seo-process-4.svg" title="Website Revision" hovertext="Refine Your Website Experience"/>
               <WdSteps imgsrc="/images/service-page/seo-process-5.svg" title="Off-Page Optimization" hovertext="Polish content and Structure"/>
            </Row>
          </Container>
        </div>
</div>
</HelmetProvider>
  )
}