import React, { Component } from 'react';
import {Container,Row,Col,ProgressBar} from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default class WhoWeAre extends Component {
  render() {
    return (
      <HelmetProvider>
      <div className='whoweare'>
        <Helmet>
        <title>Karachi’s leading Web Design Company | Digital Marketing Karachi</title>
        <meta name="description" content="We are the Karachi’s leading Web Design Company offering versatile services under one shed. We believe in a business relationship with every client and 100% satisfaction."/>
        </Helmet>
        <div className="page-banner" style={{ backgroundImage: "url(/images/pages/who-we-are-img.jpg)" }}>
          <Container>
            <Row>
              <Col><h1>Who We Are</h1>
              <p>Digiphix is a prestigious web design company offering you exclusive services across Karachi. We have helped to grow dozens of businesses to achieve their goals, and this is what we want for you too.</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-content">
          <Container>
            <Row>
              <Col md={12}>
              <p>We’ll help your business stand out by understanding your needs and will get you in front of your customers efficiently. Readout further what we have for you.</p>
              </Col>
            </Row>
          <Row>
              <Col md={12}>
                <div className='ocv'> 
                  <h2>Why Choose Digiphix?</h2>
                 <p>We are the Karachi’s leading Web Design Company offering versatile services under one shed.</p>
                <p>You need to cope with a company that will not only understand your business needs but also provides you with desired results. With a rise in e-commerce businesses in Pakistan, the other agencies work with numerous clients at once because their focus is on gaining profit and not on your success.</p>
                <p>However, we don’t do that!</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <h3>Collaborative</h3>
                <p>We make an effort to increase collaborative partnerships to comprehend our client’s essential commercial enterprise aims, which facilitates us gain excellent, measurable consequences time and time again.</p>
              </Col>
              <Col md={6}>
              <h3>Creative</h3>
              <p>Our team is creative and always engaging with new technologies. Founders Abdul and Aalee have the technical background in IT Services since 2010. We believe in a business relationship with every client and 100% satisfaction.</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <h3>Honest</h3>
                <p>We construct open and obvious conversation channels which breeds accept as true with and reliability. We need each process we do to be an endorse of our work.</p>
              </Col>
              <Col md={6}>
              <h3>Professional</h3>
              <p>It is our goal to make sure that each job, irrespective of how massive or small, is introduced on time, inside budget, with a nice that we’re proud to position our call to.</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='stretched-container'>
            <Container fluid>
              <Row>
                <Col md={6} className='ballon-overlay' style={{ backgroundImage: "url(/images/Ballon.jpg)" }}>
                    <h1>WE ARE WEB DESIGN COMPANY</h1>
                </Col>
                <Col md={6} className='bg-blue'>
                  <h4>PROVIDING EXPERT ASSISTANCE WITH BILLBOARD ADVERTISING FOR 25 YEARS.</h4>
                  <div className='progressbar-cstm'>
                  <ProgressBar animated label={'Web Design'} now={85} />
                  <br/>
                  <ProgressBar animated label={'Ecommerce'} now={90} />
                  <br/>
                  <ProgressBar animated label={'Digital Marketing'} now={80} />
                  </div>
                </Col>
              </Row>
            </Container>
        </div>
      </div>
      </HelmetProvider>
    )
  }
}
