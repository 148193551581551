import React, { Component } from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default class DigitalMarketing extends Component {
  render() {
    return (
      <HelmetProvider>
      <div className='digitalmarketing'>
        <Helmet>
        <title>Digital Marketing Pakistan | Google Business, SEO, PPC, SMM</title>
        <meta name="description" content="We are digital marketing specialists in Karachi, Pakistan. We can help to grow your google business listing, search engine optimization, pay per click, social media marketing."/>
        </Helmet>
        <div  className='dm-head'>
          <Container>
            <Row>
              <Col md={5}>
                <h1>Digital Marketing Pakistan</h1>
                <h3>Digitalize Your Web Marketing with us</h3>
                <p>We are digital marketing specialists and will help to boost your sales and increase organic audience through social media and google results. We apply smart digital marketing strategies to build your brand to the next level.</p>
                <Link to="/" className='web-link'>Explore how to Grow your business</Link>
              </Col>
              <Col md={7}>
              <img className='fullwidth' src="/images/Digital-Marketing.jpg" alt="Digital Marketing" />
              </Col>
            </Row>
          </Container>
        </div>
         <div className="ecommerce-webdesign">
               
               <Container className='zigzag-section shopify'>
                 <Row>
                   <Col md={7}>
                    <img className='fullwidth' src="/images/pages/rumina-natural.webp" alt="Magento" />
                   </Col>
                   <Col md={5}>
                   <h2>Pay-Per-Click (PPC) Marketing</h2>
                   <p>Increase your organic search visibility and get immediate results with our Pay-Per-Click (PPC) marketing services. </p>
                    <Link to="/" className='web-link'>Explore how to Grow your business</Link>
                   </Col>
                 </Row>
               </Container>
               <Container className='zigzag-section woocommerce'>
                 <Row>
                   <Col md={5}>
                   <h2>Social Media Marketing (SMM)</h2>
                   <p>We will help you create brand awareness, increase traffic and sales through our Social Media Marketing on Google, Facebook and Instagram.</p>
                    <Link to="/" className='web-link'>Explore how to Grow your business</Link>
                   </Col>
                   <Col md={7}>
                    <img className='fullwidth' src="/images/pages/becker-shoe.webp" alt="Magento" />
                   </Col>
                 </Row>
               </Container>
               <Container className='zigzag-section laravel'>
                 <Row>
                   <Col md={7}>
                    <img className='fullwidth' src="/images/pages/rumina-natural.webp" alt="Magento" />
                   </Col>
                   <Col md={5}>
                   <h2>Google Business Listing</h2>
                   <p>We take excellent measures to help you in locating your business in the best places on Google. Get the best-driven results out of your local customers who searches for your business.</p>
                    <Link to="/" className='web-link'>Explore how to Grow your business</Link>
                   </Col>
                 </Row>
               </Container>
               <Container className='zigzag-section magento'>
                 <Row>
                   <Col md={5}>
                   <h2>Search Engine Optimization (SEO)</h2>
                   <p>Through our SEO services, we'll help you rank your website in organic search results. We’ll help you with Keyword Research & Analysis, Off-Page & On-Page Optimization, and Organic Link Building Services.</p>
                   <Link to="/" className='web-link'>Explore how to Grow your business</Link>
                   </Col>
                   <Col md={7}>
                    <img className='fullwidth' src="/images/pages/becker-shoe.webp" alt="Magento" />
                   </Col>
                 </Row>
               </Container>
             </div>
    </div>
    </HelmetProvider>
    )
  }
}
