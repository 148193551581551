import React, {useState} from 'react';
import {Container, Row, Col,Form, Button} from 'react-bootstrap';
import axios from "axios"
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Contact() {
  const [sent,setSent] = useState(false)
  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [businessName, setBusinessName] = useState("")
  const [telephone,setTelephone] = useState("")
  const [subject,setSubject] = useState("")
  const [message,setMessage] = useState("")

  const handleSend = async (event) => {
    event.preventDefault();
    
    if (event.target[0].value) {
      setSent(true)
    }
    else{
      setSent(false)
    }
    try{
      await axios.post("https://mailapi.digiphix.pk/send_mail",{name,email,businessName,telephone,subject,message})
    } catch(error)
    {
      console.log(error)
    }
  }
  return (
    <HelmetProvider>
    <div className='contact-page'>
      <Helmet>
      <title>Get a Free Quote of Web Design 0300-2628850 | Ecommerce Biz</title>
      <meta name="description" content="If you are looking for web design services and digital marketing, advertising, branding, social media. feel free to call us at 0300-2628850."/>
      </Helmet>
      <div className="page-banner" style={{ backgroundImage: "url(/images/pages/website-maker-contact-us.jpg)" }}>
        <Container>
          <Row>
            <Col><h1>Contact Us</h1></Col>
          </Row>
        </Container>
      </div>
      <div className='contact-form-text'>
        <Container>
          <Row>
            <Col md={12}>
              <h2>Get in touch using the form below, and we'll get back to you</h2>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col md={8}>
            <div className='contact-form'>
              <h3>Get a free quote for your next project</h3>
              <div className='contact-bg'>
             <Form onSubmit={handleSend}>
              <Row>
                  <Col md={12}>
                  <Form.Control type="text" value={name} onChange={(e)=> setName(e.target.value)} placeholder="Your Name (Required)" required />
                  </Col>
                  <Col md={6}>
                  <Form.Control type="email" value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Your Email (Required)" required  />
                  </Col>
                  <Col md={6}>
                  <Form.Control type="text" value={businessName} onChange={(e)=> setBusinessName(e.target.value)}  placeholder="Your Business Name"  />
                  </Col>
                  <Col md={12}>
                  <Form.Control type="text" value={telephone} onChange={(e)=> setTelephone(e.target.value)} placeholder="Your Telephone Number (Required)" required />
                  </Col>
                  <Col md={12}>
                  <Form.Control type="text" value={subject} onChange={(e)=> setSubject(e.target.value)} placeholder="Subject? (Required)"  required/>
                  </Col>
                  <Col md={12}>
                  <Form.Control as="textarea" value={message} onChange={(e)=> setMessage(e.target.value)} rows={5} placeholder="How can we help you today?" />
                  </Col>
                  <Col md={12}>
                  <Button type="submit" >Send Message </Button>
                  </Col>
                </Row>
                </Form>
                {!sent ? ("") : (<p className='message-output'>Message  Send Successfully</p>)}
              </div>
              </div>
            </Col>
            <Col md={4}>
            <h5>Contact Details</h5>
            <p>Office # 711,<br/>
                7th floor,<br/>
                Mashriq Center,<br/>
                Block-14,<br/>
                Gulshan-e-iqbal,<br/>
                Karachi</p>

<h5>Phone</h5>
<p><a href="tel:02137442078">021-37442078</a></p>
<h5>Mobile</h5>
<p><a href="tel:03002628850">0300-2628850</a></p>
<h5>Email</h5>
<p><a href="mailto:info@digiphix.pk">info@digiphix.pk</a></p>
<h5>Office Hours</h5>
<p>Monday – Thursday 9:00am – 16:30pm<br/>
Friday 9:00am – 15:30pm</p> 
            </Col>
          </Row>
        </Container>

      </div>
    </div>
    </HelmetProvider>
  )
}
