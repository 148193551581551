import React, { useState } from 'react';
import {Container,Row,Col,Tabs,Tab} from 'react-bootstrap';
import WebDevelopment from './webdesignpackages/WebDevelopment';
import Seo from './webdesignpackages/Seo';
import PpcManagement from './webdesignpackages/PpcManagement';
import DomainHosting from './webdesignpackages/DomainHosting';
import SocialMediaMarketing from './webdesignpackages/SocialMediaMarketing';
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default function WebDesignPackages() { 
  const [key, setKey] = useState('web-development');
  return (
    <HelmetProvider>
    <div className='webdsgnpck'>
      <Helmet>
      <title>Web Design Packages | Best Value SEO Packages in Karachi</title>
      <meta name="description" content="Our web design, ecommerce and seo packages are affordable and drive your business higher with the best Karachi based provider with over 10 years experience."/>
      </Helmet>
    <div className="page-banner" style={{ backgroundImage: "url(/images/pages/web-design-packages.jpg)" }}>
      <Container>
        <Row>
          <Col><h1>Web Design Pakages</h1></Col>
        </Row>
      </Container>
    </div>
   <div className='packages'>
     <Container>
       <Row>
         <Col md={12}>
           <h1 className='pack-heading'>Packages</h1>
         <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="web-development" title="WEB DEVELOPMENT">
        <WebDevelopment/>
      </Tab>
      <Tab eventKey="seo" title="SEO">
      <Seo/>
      </Tab>
      <Tab eventKey="ppc-management" title="PPC Management">
      <PpcManagement/>
      </Tab>
      <Tab eventKey="domain-hosting" title="Domain Hosting">
      <DomainHosting/>
      </Tab>
      <Tab eventKey="social-media-marketing" title="Social Media Marketing">
      <SocialMediaMarketing/>
      </Tab>
    </Tabs>
         </Col>
       </Row>
     </Container>
  
   </div>
  </div>
  </HelmetProvider>
  )
}
