 import React, { Component } from 'react'
 import {Navbar,Nav,Container} from 'react-bootstrap';
 import Home from './Home';
 import WhoWeAre from './WhoWeAre';
 import Contact from './Contact';
 import Portfolio from './Portfolio';
 import Blog from './Blog';
 import WebDesignPackages from './WebDesignPackages';
 import WebDesign from './services/WebDesign';
 import EcommerceDesign from './services/EcommerceDesign';
 import DigitalMarketing from './services/DigitalMarketing';
 import Seo from './services/Seo';
 import  CompanyWebsiteDesign from './blog/CompanyWebsiteDesign.jsx';
 import  NodeDevelopmentServices from './blog/NodeDevelopmentServices.jsx';
 import ScrollToTop from "./ScrollToTop";
 import { Routes, Route, Link ,NavLink } from "react-router-dom";
 import $ from 'jquery';
export default class HeaderComp extends Component {
  componentDidMount() {
    $(".navbar-toggler").click(function(event){
      event.stopPropagation();
        $(".navbar-collapse").addClass('show');
        $(".navbar-toggler.collapsed").removeClass('collapsed');   
    });
    $(".tog-close").click(function(event){
      event.stopPropagation();
        $(".navbar-collapse").removeClass('show');
      $(".navbar-toggler").addClass('collapsed');
    });
    $(".navbar-collapse ul li a.nav-link:not(.dropdown-toggle)").click(function(){
      $(".navbar-collapse").removeClass('show');
     $(".navbar-toggler").addClass('collapsed');
     window.scrollTo({top: 0, behavior: 'smooth'}); 
    });
    $(".navbar-collapse ul li a.nav-link.dropdown-toggle").click(function(){
      $(".navbar-collapse ul li.drop-down ul").toggleClass('show');
    });
}
  render() {
    return (
      <>

      <header className="main-header">
  <Navbar>
  <Container>
    <Navbar.Brand as={Link} to='/'> <img src="/images/logo.gif" alt="Logo" /> </Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll"/>
    <Navbar.Collapse id="navbarScroll">
      <Nav
        className="my-2 my-lg-0"
        navbarScroll
      >
        <button id='tog-close' className='tog-close'>X</button>
        <ul>
          <li><NavLink  activeclassname="active" className="nav-link"  to='/'>Home</NavLink></li>
          <li><NavLink  activeclassname="active" className="nav-link" to='/about-web-design-company'>Who We Are</NavLink></li>
          <li className='drop-down'><NavLink  activeclassname="active" className="nav-link dropdown-toggle" to='/services' onClick={(e) => {e.preventDefault();}}>Services</NavLink>
          <ul>
          <li><NavLink   className="nav-link" to='/web-design-karachi'>Web Design</NavLink></li>
          <li><NavLink   className="nav-link"  to='/ecommerce-website-design'>Ecommerce Website Design</NavLink></li>
          <li><NavLink   className="nav-link"  to='/digital-marketing'>Digital Marketing</NavLink></li>
          <li><NavLink   className="nav-link"  to='/seo-karachi'>SEO</NavLink></li>
          </ul>
          </li>
          <li><NavLink  activeclassname="active" className="nav-link" to='/portfolio'>Portfolio</NavLink></li>
          <li><NavLink  activeclassname="active" className="nav-link" to='/web-design-packages'>Package</NavLink></li>
          <li><NavLink  activeclassname="active" className="nav-link" to='/blog'>Blog</NavLink></li>
          <li><NavLink  activeclassname="active" className="nav-link" to='/contact'>Contact</NavLink></li>
        </ul>
      </Nav>
      <div className="cont-info">
        <strong>Phone : </strong> <a href="tel:02137442078">021-37442078</a><br/>
        <strong>Mobile : </strong> <a href='tel:03002628850'>0300-2628850</a>
        </div>
    </Navbar.Collapse>
  </Container>
</Navbar>
</header>
<ScrollToTop>
  <Routes>
    <Route exact path="/" element={<Home />}/>
    <Route path="/about-web-design-company" element={<WhoWeAre />}/>
    <Route path="/portfolio" element={<Portfolio />}/>
    <Route path="/blog" element={<Blog />}/>
    <Route path="/contact" element={<Contact />}/>
    <Route path="/web-design-karachi" element={<WebDesign />}/>
    <Route path="/web-design-packages" element={<WebDesignPackages/>}/>
    <Route path="/ecommerce-website-design" element={<EcommerceDesign/>}/>
    <Route path="/digital-marketing" element={<DigitalMarketing/>}/>
    <Route path="/seo-karachi" element={<Seo/>}/>
    <Route path="/10-company-website-design-inspiration-templates" element={<CompanyWebsiteDesign/>}/>
    <Route path="/node-js-development-services" element={<NodeDevelopmentServices/>}/>
  </Routes>
</ScrollToTop>
      </>
    )
  }
}
