import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {Row,Col} from 'react-bootstrap';
export default class SocialMediaMarketing extends Component {
  render() {
    return (
        <div className="tab-innercontent">
            <div className='tab_head'>
                <h1 className='package-heading'>Social Media <strong>Marketing</strong></h1>
                <img src="/images/head_bottom.png" alt="Heading Line" />
            </div>
            <div className="digitab-body">
                <Row>
                    <Col md={4}>
                        <div className="plan-box">
                            <div className="top">
                            <h5>Simple Start</h5>
                            <h4>Starter Plan</h4>
                            <p>Suitable for newly formed organizations or small incubated startups</p>
                            </div>
                            <div className="mid">
                                <ul className='scroll-me'>
                                    <li>3-4 pages</li>
                                    <li>banner image</li>
                                    <li>social media integration</li>
                                    <li>mobile repsonsive</li>
                                    <li>basic content</li>
                                    <li>contact form</li>
                                    <li>header</li>
                                    <li>footer</li>
                                </ul>
                            </div>
                            <div className="bottom">
                                <div className='left'><p>Once Off Payment</p></div>
                                <div className='right'>
                                    <del>£499</del>
                                    <h5>£350</h5>
                                </div>
                                <div className='btm'>
                                    <Link to="/" className='fom-btn'>Start Talking</Link>
                                </div>
                            </div>
                        </div></Col>
                    <Col md={4}>
                    <div className="plan-box no2">
                            <div className="top">
                            <h5>Essentials</h5>
                            <h4>Scaling Plan</h4>
                            <p>For medium-sized stable organizations looking to climb up the corporate ladder.</p>
                            </div>
                            <div className="mid">
                                <ul className='scroll-me'>
                                    <li>6-7 pages</li>
                                    <li>banner image</li>
                                    <li>Mobile responsive</li>
                                    <li>SEO friendly content</li>
                                    <li>Contact form</li>
                                    <li>Social media integrations</li>
                                    <li>Social media feed</li>
                                    <li>Faq page</li>
                                    <li>Banners for blog page</li>
                                </ul>
                            </div>
                            <div className="bottom">
                                <div className='left'><p>Once Off Payment</p></div>
                                <div className='right'>
                                    <del>£799</del>
                                    <h5>£650</h5>
                                </div>
                                <div className='btm'>
                                    <Link to="/" className='fom-btn'>Start Talking</Link>
                                </div>
                            </div>
                        </div>
                    
                    </Col>
                    <Col md={4}>
                    <div className="plan-box no3">
                            <div className="top">
                            <h5>Business Plus</h5>
                            <h4>Venture Plan</h4>
                            <p>For pre-established businesses that aim to maintain their presence and claim the crown.</p>
                            </div>
                            <div className="mid">
                                <ul className='scroll-me'>
                                    <li>Brand logo</li>
                                    <li>UX/UI Design</li>
                                    <li>Search</li>
                                    <li>Product and Categories</li>
                                    <li>Payment Integration</li>
                                    <li>SEO on page for products</li>
                                    <li>Layouts and Design</li>
                                    <li>Blog pages</li>
                                    <li>Terms and conditions page</li>
                                </ul>
                            </div>
                            <div className="bottom">
                                <div className='left'><p>Once Off Payment</p></div>
                                <div className='right'>
                                    <del>£1199</del>
                                    <h5>£999</h5>
                                </div>
                                <div className='btm'>
                                    <Link to="/" className='fom-btn'>Start Talking</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
      
    )
  }
}
