import React, { Component } from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import {Helmet, HelmetProvider } from 'react-helmet-async';
import WdSteps from './sections/WdSteps';
export default class Seo extends Component {
  render() {
    return (
      <HelmetProvider>
      <div className='seo-page'>
        <Helmet>
        <title>SEO Company Karachi | Search Engine Optimization (SEO)</title>
        <meta name="description" content="We're an SEO Company in Karachi with over 10 years of experience in optimizing websites for better Search Engine rankings. We can build your brand, and grow your business with our help!"/>
        </Helmet>
      <div className="page-banner" style={{ backgroundImage: "url(/images/pages/SEO-services-karachi.jpg)" }}>
      <Container>
        <Row>
        <Col><h1>SEO</h1></Col>
        </Row>
      </Container>
    </div>
             <div className="what-we-do seo-service">
               <Container>
                 <Row>
                   <Col md={6}><h3>WHAT WE DO</h3>
                    <h1>SEO</h1>
                    <h4>Our high-end and affordable SEO Services will expand your website's reach in no time.</h4>
                    <p>It's the next step to making your business go and stay on top of your rankings. Optimize your website, build your brand, and grow your business with our help!</p>
                   </Col>
                   <Col md={6}>
                     <img src="/images/service-page/seo-img.svg" alt="SEO" width="100%" />
                   </Col>
                 </Row>
               </Container>
               <Container className='own-cstm-wbst-dsn text-center'>
                 <Row>
                   <Col md={12}>
                   <h2>Take a Step to Our SEO Process</h2>
                    <p>Bring attention to your brand. Optimize now!</p>
                   </Col>
                   
                 </Row>
                 <Row className='steps-box'>
                    <WdSteps imgsrc="/images/service-page/seo-process-1.svg" title="Website Audit" hovertext="Have your website’s visibility analyzed"/>
                    <WdSteps imgsrc="/images/service-page/seo-process-2.svg" title="Search Engine Submission" hovertext="Introduce your URL to a Search Engine"/>
                    <WdSteps imgsrc="/images/service-page/seo-process-3.svg" title="Keyword Research and Distribution" hovertext="Enrich Your website For better searchability"/>
                    <WdSteps imgsrc="/images/service-page/seo-process-4.svg" title="Website Revision" hovertext="Refine Your Website Experience"/>
                    <WdSteps imgsrc="/images/service-page/seo-process-5.svg" title="Off-Page Optimization" hovertext="Polish content and Structure"/>
                 </Row>
               </Container>
             </div>
    </div>
    </HelmetProvider>
    )
  }
}
